.header {
	h1 {
		color: #ffcc00;
		text-align: center;
		padding: 0.8em 0;
		text-shadow: 1px 1px #878787;
		margin: 0;
	}

	p {
		text-align: center;
	}

	.ant-menu-light {
		background: none;
		border-bottom: none;
		justify-content: center;
	}
}
